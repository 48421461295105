import React from 'react'
import { graphql } from 'gatsby'
import Card from '@molecules/cards/Card'
import MasonryGrid from '@organisms/masonry/MasonryGrid'
import LandingPageLayout, {
  LandingPageContextProps,
} from 'templates/layouts/LandingPageLayout'

interface Props {
  data: Queries.ProjectLandingQuery
  pageContext: LandingPageContextProps
}

const ProjectLanding = ({ data, pageContext }: Props) => {
  const landing: Queries.DatoCmsLandingPage = data.datoCmsLandingPage
  const projects: Queries.DatoCmsProject[] = data.allDatoCmsProject.edges
  const highlightProject: Queries.DatoCmsProject =
    data.highlightProject.edges[0].node

  const showHighlight = highlightProject && pageContext.currentPage === 1
  const firstItem: any = projects[0]
  const firstPost: Queries.DatoCmsProject = {
    ...firstItem.node,
    ...highlightProject,
  }

  return (
    <LandingPageLayout
      metadata={{
        seoTags: landing.seoMetaTags,
        currentPage: pageContext.currentPage,
      }}
      heroProps={{
        mainHeading: landing.mainHeading,
        smallHeading: landing.smallHeading,
        text: landing.heroText,
        imagePortrait: landing.imagePortrait.gatsbyImageData,
        imageLandscape: landing.imageLandscape.gatsbyImageData,
      }}
      pageContext={pageContext}
    >
      {showHighlight && <Card data={firstPost} highlight />}

      <MasonryGrid className="project-landing">
        {projects.map((item: any, index: number) => {
          const project: Queries.DatoCmsProject = item.node
          if (!showHighlight || (showHighlight && index > 0)) {
            return <Card key={project.originalId} data={project} />
          }
        })}
      </MasonryGrid>
    </LandingPageLayout>
  )
}

export const pageQuery = graphql`
  query ProjectLanding($skip: Int!, $perPage: Int!, $originalId: String!) {
    datoCmsLandingPage(originalId: { eq: $originalId }) {
      ...LandingPageTitleHero
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    highlightProject: allDatoCmsProject(
      limit: 1
      sort: { fields: [meta___createdAt], order: [DESC] }
    ) {
      edges {
        node {
          heroImageMainLarge: heroImage {
            gatsbyImageData(
              width: 1120
              layout: CONSTRAINED
              imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
            )
          }
          heroImageHoverLarge: heroImage {
            gatsbyImageData(
              width: 1120
              layout: CONSTRAINED
              imgixParams: {
                ar: "4:3"
                fit: "crop"
                crop: "focalpoint"
                sat: -100
              }
            )
          }
          heroImageMainSmall: heroImage {
            gatsbyImageData(
              width: 1120
              layout: CONSTRAINED
              imgixParams: { ar: "16:8", fit: "crop", crop: "focalpoint" }
            )
          }
          heroImageHoverSmall: heroImage {
            gatsbyImageData(
              width: 1120
              layout: CONSTRAINED
              imgixParams: {
                ar: "16:8"
                fit: "crop"
                crop: "focalpoint"
                sat: -100
              }
            )
          }
        }
      }
    }
    allDatoCmsProject(
      limit: $perPage
      skip: $skip
      sort: { fields: [meta___createdAt], order: [DESC] }
    ) {
      edges {
        node {
          ...cardProject
        }
      }
    }
  }
`

export default ProjectLanding
